<template>
  <div style="background: #F6F6F6; height: 100%;">
    <van-nav-bar
      title="查看物流"
      left-arrow
      fixed
      placeholder
      @click-left="$router.go(-1)"
    />

    <div class="title">
       <img :src="x" alt="" v-for=" (x, y) in picUrl" :key="y">
       <span>
         {{ 
          expressStatus=='0'?'已下单'
          :expressStatus=='1'?'揽收'
          :expressStatus=='2'?'疑难'
          :expressStatus=='3'?'签收'
          :expressStatus=='4'? '退签'
          :expressStatus=='5'? '派件'
          :expressStatus=='8'? '清关'
          :expressStatus=='14'? '拒签': '暂无状态'
        }}
       </span>
    </div>

    <div class="flow">
      <p>
        <span>{{ logisticsDesc }}：{{ logisticsNo }}</span>
        <span style="color: #999;">
          <span class="copy" @click="copy(logisticsNo)">复制</span>
        </span>
      </p>
      
      <van-steps direction="vertical" :active="100" active-color="#3E85FF">
        <van-step>
          <div style="color: #333;">
            <div style="margin-bottom: 8px;">{{ info.userName }} {{ info.telNum }}</div>
            <div>{{ info.address }}</div>
          </div>
        </van-step>
        <van-step v-for="(item, index) in logisticsStep" :key="index">
          <!-- <div>已揽件</div> -->
          <div class="Ge-step">
            <div class="Ge-step-left">
              <div>{{ item.date }}</div>
              <div>{{ item.timeing}}</div>
              <!-- <span>{{ item.time }}</span> -->
            </div>
            <div class="Ge-step-right">
              {{ item.context }}
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { queryExpress } from '@/api/tsMallorderDetail'
import ClipBoard from 'clipboard'
export default {
  data () {
    return {
      picUrl: '',
      logisticsDesc: '',
      logisticsNo: '',
      expressStatus: '', // 当前状态
      info: {},
      logisticsStep: []
    }
  },

  created () {
    if (this.$route.query.session) {
      this.$store.commit('setSession',  this.$route.query.session)
    }
    this.picUrl = this.$route.query.data.picUrl
    this.logisticsDesc = this.$route.query.data.logisticsDesc
    this.logisticsNo = this.$route.query.data.logisticsNo
    this.info = this.$route.query.info
    const data = {
      logistics: this.$route.query.data.logistics,
      logisticsNo: this.$route.query.data.logisticsNo
    }
    this.queryExpress(data)
  },
  
  methods: {
    queryExpress (data) {
      queryExpress(data).then(res => {
        if (res.data.code ===0) {
          this.expressStatus = res.data.data.state
          const steps = res.data.data.data
          steps.forEach(x => {
            let time = new Date(x.time.replace(/-/g, '/'))
            x.date = `${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`
            let hours = `${time.getHours()}`
            if (hours.length == 1) {
              hours = `0${hours}`
            }
            let minute = `${time.getMinutes()}`
            if (minute.length == 1) {
              minute = `0${minute}`
            }
            x.timeing = `${hours}:${minute}`
          })
          this.logisticsStep = JSON.parse(JSON.stringify(steps))
          console.log(this.logisticsStep)
        }
      })
    },

    // 复制
    copy (logisticsNo) {
      const clipBoard = new ClipBoard('.copy', {
        text: () => {
          return  logisticsNo
        }
      })
      clipBoard.on('success', () => {
        this.$toast('复制成功')
        clipBoard.destroy()
      })
      clipBoard.on('error', () => {
        this.$toast('复制失败')
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}
.title {
  background: white;
  display: flex;
  padding: 22px 10px;
  font-size: 14px;
  img {
    margin-right: 17px;
    width: 60px;
    height: 60px;
  }
}
.flow {
  background: white;
  width: 91.5%;
  box-sizing: border-box;
  margin: 15px auto 0;
  padding-top: 20px;
  p  {
    padding: 21px 5% 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
.Ge-step {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;
  &-left {
    font-size: 12px;
  }
  &-right {
    flex: 1;
    background: #F7F7F7;
    min-height: 54px;
    padding: 12px 13px 0;
    margin-left: 15px;
  }
}
.copy:active {
  color: #FB2B53;
}
</style>